import { useSwiper } from 'swiper/react';
import { SwipperButtonProps } from './SwiperButtonNext';

export const SwiperButtonPrev = (prop: SwipperButtonProps) => {
  const swiper = useSwiper();
  return (
    <button
      aria-label="Previous button"
      onClick={() => swiper.slidePrev()}
      className={[
        'bg-white  -ml-2 lg:-ml-4 flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none',
        prop.className
      ].join(' ')}>
      <svg role="presentation" viewBox="0 0 20 20" fill="currentColor" className="chevron-left w-6 h-6 ">
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"></path>
      </svg>
    </button>
  );
};
