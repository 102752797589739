import { useEffect, useState } from 'react';

import APIService from '../../services/services';
import { useParams, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { PromoDetailView } from '../../helpers/types';
import { Helmet } from 'react-helmet';

import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import PhoneForm from '../layout/PhoneForm';
import EmailForm from '../layout/EmailForm';
import { callMe } from '../../helpers/functions';
import StarRatings from 'react-star-ratings';
//import { callMe } from '../../helpers/functions';

function AdvisorOffer() {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<PromoDetailView>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  // const onCall = (agent: AgentViewModel) => {
  //   setModalOpen('Phone');
  //   callMe(agent);
  // };

  const breadCrumb = () => (
    <div className="text-base text-black gap-1 bg-gtc8 p-1.5">
      <a href="/" className="pr-1">
        Home {'>'}
      </a>
      <a href="/advisor-offers" className="pr-1">
        Advisor Offer {'>'}
      </a>
      <div className="text-gtc9 pr-1 inline">{data?.promo.title}</div>
    </div>
  );

  const matchedAgentProfile = () => (
    <div className="flex flex-col p-1 bg-gtc6 w-full md:w-[135px] h-full items-center gap-2 text-center mt-3">
      <div className="text-center text-base md:text-xs font-bold p-1">PLAN YOUR NEXT TRIP WITH</div>
      <img
        src={
          data?.matchedAgent.agentFullBio.toLowerCase() === 'unpublished' ? '/images/gtc_stacked_black.png' : data?.matchedAgent.photoUrl
        }
        className="w-[115px] bg-white"
        alt={`Photo of ${data?.matchedAgent.firstName}`}
      />
      <div className="font-bold font-[Avenir-lt-Std] text-xl uppercase">{data?.matchedAgent.firstName}</div>
      {(data?.matchedAgent.rating || 0) > 0 && (
        <StarRatings
          rating={data?.matchedAgent.rating}
          starRatedColor="#ff6d41"
          // changeRating={this.changeRating}
          numberOfStars={5}
          starDimension="16"
          starSpacing="0.5px"
          name="rating"
        />
      )}
      <div className="text-xs">{data?.matchedAgent.agencyName}</div>
      <div className="flex flex-row gap-1 w-full">
        <button
          className={[
            ' w-full h-10 flex justify-center items-center flex-1',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          onClick={() => {
            setModalOpen('Email');
          }}
          title={`Email ${data?.matchedAgent.firstName}`}
          aria-label={`Email ${data?.matchedAgent.firstName}`}
          role="button"
          tabIndex={0}>
          <i role="presentation" className="fa-solid fa-envelope text-base text-white"></i>
        </button>
        <button
          className={[
            ' w-full h-10 flex justify-center items-center flex-1',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          onClick={() => {
            setModalOpen('Phone');
            if (data?.matchedAgent) callMe(data?.matchedAgent);
          }}
          title={`Call ${data?.matchedAgent.firstName}`}
          aria-label={`Call ${data?.matchedAgent.firstName}`}
          role="button"
          tabIndex={0}>
          <i role="presentation" className="fa-solid fa-phone text-base text-white"></i>
        </button>
      </div>
      {data?.matchedAgent.agentFullBio.toLowerCase() !== 'unpublished' && (
        <a
          href={data?.matchedAgent.detailsUrl}
          className={[
            ' w-full h-12 flex justify-center items-center py-3 text-xs text-white',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          title={`View ${data?.matchedAgent.firstName}`}>
          VIEW BIO
        </a>
      )}
    </div>
  );
  const getPromo = () => {
    setLoading(true);
    if (id) {
      const parsed = queryString.parse(searchParams.toString());
      APIService()
        .gtcService.fetchPromo(
          parseInt(id),
          parsed.agentId ? (parsed.agentId as unknown as number) : 0,
          parsed.preview ? (parsed.preview as unknown as boolean) : false
        )
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setData(res.data);
          } else {
            setData(undefined);
          }
        });
    }
  };

  useEffect(() => {
    getPromo();
  }, []);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={`${data?.promo.title} | Global Travel Collection`} />
        <meta name="description" content={`${data?.promo.title}`} />
        <title>{data?.promo.title || ''} | Global Travel Collection</title>
        <link rel="canonical" href={window.location.href}></link>
      </Helmet>

      {loading ? (
        <div className="w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-10 flex flex-col items-center justify-center col-span-2">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <>
          {data && (
            <>
              {breadCrumb()}
              <section id="mainsec" className="w-full">
                <div
                  className="large-hero justify-center px-4 items-center flex"
                  role="img"
                  aria-label="hero img"
                  style={{
                    backgroundImage: `url(${data.promo.heroImageUrl})`,
                    backgroundSize: 'cover',
                    boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.2)'
                  }}>
                  <div className="flex flex-col items-center ">
                    <div>
                      <h1 className="text-2xl sm:text-65xl text-gtc4 text-center font-normal font-[Avenir-lt-Std]">{data.promo.title}</h1>
                    </div>
                  </div>
                </div>
              </section>
              <div className="md:mx-auto bg-white 2xl:container px-2 md:px-4">
                <section className=" mx-2 md:mx-20">
                  {data.matchedAgent && (
                    <section className="mt-4">
                      <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-10">
                        <div className="w-full divBlogStory" dangerouslySetInnerHTML={{ __html: data.promo.promotionBody }} />
                        {matchedAgentProfile()}
                      </div>
                    </section>
                  )}

                  <section className="mt-10 mb-7">
                    <div className="uppercase text-center mb-4 md:text-3xl font-[Avenir-lt-Std]">
                      lean on our expertise to guide you to your next vacation
                    </div>
                    {data.promo.logoBannerImage ? (
                      <div className="flex md:mx-0">
                        <img
                          className="w-full mx-auto"
                          src={data.promo.logoBannerImage.thumbnail}
                          alt={data.promo.logoBannerImage.altTag}
                          // width={920}
                          height={110}
                        />
                      </div>
                    ) : (
                      <div className="grid grid-rows-1 md:grid-rows-3 gap-4 grid-flow-row-dense">
                        {data.promo.promotionSuppliers.map((supplier) => (
                          <div className="flex flex-col gap-1 items-center" key={supplier.supplierID}>
                            <div>
                              <img className="w-full mx-auto" src={supplier.supplierLogo} alt={supplier.supplierName} height={110} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </section>
                </section>
              </div>
            </>
          )}
        </>
      )}

      <Modal
        isOpen={modalOpen !== null && (data?.matchedAgent ? true : false)}
        onRequestClose={() => setModalOpen(null)}
        style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {data?.matchedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...data?.matchedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && (
                // eslint-disable-next-line react/jsx-no-undef
                <PhoneForm agent={data?.matchedAgent} callback={() => setModalOpen('Email')} />
              )}
              {modalOpen === 'Email' && <EmailForm agent={data?.matchedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default AdvisorOffer;
