import { useSwiper } from 'swiper/react';

export interface SwipperButtonProps {
  className: string;
}

export const SwiperButtonNext = (prop: SwipperButtonProps) => {
  const swiper = useSwiper();
  return (
    <button
      aria-label="Next button"
      onClick={() => swiper.slideNext()}
      className={[
        'bg-white -mr-2 lg:-mr-4 flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none',
        prop.className
      ].join(' ')}>
      <svg role="presentation" viewBox="0 0 20 20" fill="currentColor" className="chevron-right w-6 h-6">
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"></path>
      </svg>
    </button>
  );
};
