import { useEffect, useState } from 'react';

import APIService from '../../services/services';
import { useParams, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { AgentViewModel, PromoDetailView } from '../../helpers/types';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import PhoneForm from '../layout/PhoneForm';
import EmailForm from '../layout/EmailForm';
import { callMe } from '../../helpers/functions';

function SoarEmail() {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<PromoDetailView>();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const onCall = (agent: AgentViewModel) => {
    setModalOpen('Phone');
    console.log(agent);
    callMe(agent);
  };

  const getPromo = () => {
    setLoading(true);
    if (id) {
      const parsed = queryString.parse(searchParams.toString());
      APIService()
        .gtcService.fetchPromo(
          parseInt(id),
          parsed.agentId ? (parsed.agentId as unknown as number) : 0,
          parsed.preview ? (parsed.preview as unknown as boolean) : false
        )
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setData(res.data);
          } else {
            setData(undefined);
          }
        });
    }
  };

  useEffect(() => {
    getPromo();
  }, []);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
      </Helmet>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Fetching...</h2>
        </div>
      )}
      {data && (
        <>
          <section id="mainsec" className="w-full">
            <div
              className="h-96 justify-center px-4 items-center flex"
              style={{
                backgroundImage: `url(${data.promo.heroImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: '50%'
              }}></div>
          </section>
          <div className="md:mx-auto bg-white 2xl:container mx-2">
            <div className="bg-black p-3 mb-7 text-white flex flex-col items-center text-center gap-1 -mx-4 md:mx-0">
              <h1 className="text-base m-0">{data.promo.title}</h1>
            </div>

            {data.matchedAgent && (
              <section>
                <div className="flex flex-col  gap-4">
                  <div className="w-full" dangerouslySetInnerHTML={{ __html: data.promo.promotionBody }} />
                </div>

                <div className="w-full flex justify-center">
                  <div className="bg-gtc4 flex flex-row gap-4   md:h-52">
                    <div className="flex flex-col items-center gap-2 sm:flex-row">
                      <div className="w-full">
                        <a href={data.matchedAgent.agentDetailsUrl}>
                          <img
                            className="mx-auto h-32 md:h-52"
                            src={data.matchedAgent.notEmptyPhotoUrl}
                            alt={data.matchedAgent.photoUrlAlt}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="flex flex-col p-2 gap-2 md:gap-0 md:justify-between flex-1">
                      <div className=" flex flex-col ">
                        <div className="uppercase text-sm md:text-xl">{data.matchedAgent.fullName}</div>
                        <div className="text-xs md:text-base">{data.matchedAgent.title}</div>
                      </div>
                      <div className="text-xs md:text-base">{data.matchedAgent.location}</div>
                      {data.matchedAgent.destinations.length > 0 && (
                        <div className="flex flex-col gap-1 text-xs md:text-base">
                          <div className="font-bold uppercase">Destination</div>
                          <div>{data.matchedAgent.destinations.join(', ')}</div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col justify-between">
                      <div className="flex flex-col md:flex-row gap-1">
                        <div className="md:flex-1 md:w-20">
                          <a
                            href={data.matchedAgent.agentDetailsUrl}
                            className="bg-gtc7 hover:bg-black w-20 h-12 flex justify-center items-center cursor-pointer">
                            <i role="presentation" className="fa-solid fa-user fa-2xl text-white"></i>
                          </a>
                        </div>
                        <div className="md:flex-1 md:w-20">
                          <div
                            className="bg-gtc7 hover:bg-black h-12 flex justify-center items-center cursor-pointer"
                            title={`Email ${data.matchedAgent.fullName}`}
                            onClick={() => {
                              setModalOpen('Email');
                            }}>
                            <i role="presentation" className="fa-solid fa-envelope fa-2xl text-white"></i>
                          </div>
                        </div>
                        <div className="md:flex-1 md:w-20">
                          <div
                            className="bg-gtc7 hover:bg-black h-12 flex justify-center items-center cursor-pointer"
                            title={`Call ${data.matchedAgent.fullName}`}
                            onClick={() => onCall(data.matchedAgent)}>
                            <i role="presentation" className="fa-solid fa-phone fa-2xl text-white"></i>
                          </div>
                        </div>
                      </div>
                      <div className="md:flex justify-end mr-2 mb-2 hidden-tw">
                        <a href={data.matchedAgent.agentDetailsUrl}>
                          {' '}
                          Show full profile <i role="presentation" className="fa-solid fa-arrow-right"></i>{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <section className="mt-10 mb-7">
              {data.promo.logoBannerImage ? (
                <div className="flex md:mx-20">
                  <img className="w-full mx-auto" src={data.promo.logoBannerImage.thumbnail} alt={data.promo.logoBannerImage.altTag} />
                </div>
              ) : (
                <div className="grid grid-rows-1 md:grid-rows-3 gap-4 grid-flow-row-dense">
                  {data.promo.promotionSuppliers.map((supplier) => (
                    <div className="flex flex-col gap-1 items-center" key={supplier.supplierID}>
                      <div>
                        <img className="w-full mx-auto" src={supplier.supplierLogo} alt={supplier.supplierName} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </section>
          </div>
        </>
      )}

      <Modal
        isOpen={modalOpen !== null && (data?.matchedAgent ? true : false)}
        onRequestClose={() => setModalOpen(null)}
        style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {data?.matchedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...data?.matchedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && (
                // eslint-disable-next-line react/jsx-no-undef
                <PhoneForm agent={data?.matchedAgent} callback={() => setModalOpen('Email')} />
              )}
              {modalOpen === 'Email' && <EmailForm agent={data?.matchedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default SoarEmail;
