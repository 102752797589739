/* eslint-disable no-unused-vars */
import { useRef, useState } from 'react';
import { AgentDetailViewModel, StoryItemViewModel } from '../../../helpers/types';
import 'react-image-gallery/styles/css/image-gallery.css';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import { SwiperButtonNext } from '../../../components/SwiperButtonNext';
import { SwiperButtonPrev } from '../../../components/SwiperButtonPrev';

interface GetInspiredProps {
  title: string;
  data: StoryItemViewModel[];
}

function GetInspired({ data, title }: GetInspiredProps) {
  const cardWidth = data.length < 3 ? 'w-full md:w-full' : 'w-full';
  const cardWrapper = () => {
    switch (data.length) {
      case 3:
        return 'md:px-0  md:mr-0';
      case 2:
        return '';
      case 1:
        return '';

      default:
        return 'md:px-12';
    }
  };
  return (
    <>
      <div className=" my-10 bg-black text-white md:p-10 p-2 w-full mx-auto">
        <div className="text-lg md:text-3xl text-center mb-10">{title}</div>

        <div className={['relative md:mx-auto  px-0 -mr-2 ', cardWrapper()].join(' ')}>
          {data.length > 4 && (
            <>
              <div className="absolute inset-y-0 left-0 z-10 hidden-tw md:flex items-center button-prev">
                <button className=" -ml-2 lg:-ml-4 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <rect width="30" height="48" fill="#C0C0C0" />
                    <g id="Profile - 1440 px" clipPath="url(#clip0_1072_24882)">
                      <rect width="1440" height="4017" transform="translate(-404 -1119)" fill="black" />
                      <g id="Group 1788">
                        <g id="Group 1787">
                          <g id="Arrows">
                            <path
                              id="Vector"
                              d="M30 5.64L11.4575 24L30 42.36L24.2915 48L2.08066e-06 24L24.2915 -5.03246e-07L30 5.64Z"
                              fill="#D9D9D9"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1072_24882">
                        <rect width="1440" height="4017" fill="white" transform="translate(-404 -1119)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="absolute inset-y-0 right-0 z-10 hidden-tw md:flex items-center button-next">
                <button className=" -mr-2 lg:-mr-4 flex justify-center items-center  focus:outline-none">
                  <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="30" height="48" viewBox="0 0 30 48" fill="none">
                    <path
                      id="Vector"
                      d="M6.19703e-08 42.36L18.5425 24L8.251e-09 5.64L5.7085 0L30 24L5.7085 48L6.19703e-08 42.36Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
          <Swiper
            spaceBetween={20}
            slidesPerView={1.5}
            loop={false}
            slidesPerGroupSkip={1}
            modules={[Navigation]}
            navigation={{
              prevEl: '.button-prev',
              nextEl: '.button-next'
            }}
            breakpoints={{
              400: {
                slidesPerView: 1.5,
                spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 0,
                centeredSlides: false,
                centeredSlidesBounds: false
              },
              1024: {
                slidesPerView: 4,
                // spaceBetween: 20,
                centeredSlides: false,
                centeredSlidesBounds: false
              }
            }}>
            <div className="px-20">
              {data.map((x, index) => (
                <>
                  <SwiperSlide key={x.title}>
                    <div className="swiper-slide p-1 ">
                      <a title={x.title} href={x.url} className="cursor-pointer">
                        <div className="w-full">
                          <img className={[cardWidth, ' object-cover '].join(' ')} src={x.thumbnailImage} alt={x.title} />
                        </div>
                        <div className={[cardWidth, 'text-center bg-black min-h-[120px] py-4 flex justify-center items-center'].join(' ')}>
                          <p className="m-0 text-sm  md:text-lg text-white uppercase font-normal font-[Avenir-lt-Std] px-2">{x.title}</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                </>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default GetInspired;
