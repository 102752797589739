function AgentSearchFooter() {
  return (
    <section className="flex flex-col gap-10 mb-4 flex-1">
      <div className="flex flex-col md:flex-row gap-10 flex-1">
        <div className="flex-1">
          <div className="mb-4 text-lg">
            <div>
              <strong>Travel Agents by Destination Expertise</strong>
            </div>
          </div>
          <div className="flex flex-col md:flex-row  gap-4">
            <div className="flex flex-col  flex-1">
              <div className="flex flex-col gap-1">
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Africa">
                    Africa
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Antarctica">
                    Antarctica
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Asia">
                    Asia
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Australia & New Zealand">
                    Australia & New Zealand
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Canada">
                    Canada
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Caribbean">
                    Caribbean
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Central America">
                    Central America
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Europe">
                    Europe
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 flex-1">
              <div className="flex flex-col gap-1">
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Hawaii | USA">
                    Hawaii
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Mexico">
                    Mexico
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Middle East">
                    Middle East
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=South America">
                    South America
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=South Pacific">
                    South Pacific
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Southeast Asia | East and Southeast Asia">
                    Southeast Asia
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=USA">
                    USA
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctDestination=Worldwide">
                    Worldwide
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1">
          <div className="mb-4 text-lg">
            <div>
              <strong>Travel Agents by Specialty</strong>
            </div>
          </div>
          <div className="flex flex-col md:flex-row  gap-1">
            <div className="flex-1">
              <div className="flex flex-col gap-1">
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Accessible Travel">
                    Accessible Travel
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Adventure">
                    Adventure
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Arts & Culture">
                    Arts & Culture
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Business Travel">
                    Business Travel
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Cruises">
                    Cruises
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Destination Weddings">
                    Destination Weddings
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Eco-Tourism">
                    Eco-Tourism
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Family Vacations">
                    Family Vacations
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Food & Wine">
                    Food & Wine
                  </a>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col gap-1">
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Groups">
                    Groups
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Honeymoons">
                    Honeymoons
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=LGBT Travel">
                    LGBT Travel
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Luxury Hotels">
                    Luxury Hotels, Resorts & Villas
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Meetings & Incentives">
                    Meetings & Incentives
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Private Escorted Tours">
                    Private Escorted Tours
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Safari">
                    Safari
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=Wellness">
                    Spa & Wellness
                  </a>
                </div>
                <div>
                  <a
                    className="hover:text-red-500"
                    href="/find-an-elite-travel-agent?slctInterest=  Yacht Charters"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1">
        <div className="mb-4 text-lg">
          <div>
            <strong>Travel Agents by Location</strong>
          </div>
        </div>
        <div className="flex flex-col md:flex-row  gap-4">
          <div className="flex-1">
            <div className="flex flex-col gap-1">
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=TX&slctMetro=Austin">
                  Austin, TX
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=Los+Angeles&slctCity=Beverly+Hills">
                  Beverly Hills, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=FL&slctMetro=West+Palm+Beach-Ft.+Pierce&slctCity=Boca+Raton">
                  Boca Raton, FL
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=MA&slctMetro=Boston&slctCity=Boston">
                  Boston, MA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=IL&slctMetro=Chicago">
                  Chicago, IL
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-col gap-1">
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=Los+Angeles&slctCity=Encino">
                  Encino, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=MI&slctMetro=Grand+Rapids-Kalamazoo-Battle+Creek">
                  Grand Rapids, MI
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=NY&slctMetro=New+York&slctCity=Harrison">
                  Harrison, NY
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=NY&slctMetro=New+York&slctCity=Westbury">
                  Long Island, NY
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=FL&slctMetro=Miami-Ft.+Lauderdale&slctCity=Miami">
                  Miami, FL
                </a>
              </div>
            </div>
          </div>
          <div className=" flex-1">
            <div className="flex flex-col gap-1">
              <div>
                <a className="hover:text-red-500" href="/find-an-elite-travel-agent?slctState=NJ">
                  New Jersey
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=NY&slctMetro=New+York&slctCity=New+York">
                  New York City
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=Los+Angeles&slctCity=Corona+del+Mar">
                  Corona del Mar, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=Palm+Springs&slctCity=Palm+Desert">
                  Palm Desert, CA
                </a>
              </div>
            </div>
          </div>
          <div className=" flex-1">
            <div className="flex flex-col gap-1">
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=San+Diego">
                  San Diego, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=San+Francisco-Oakland-San+Jose">
                  San Francisco, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=AZ&slctMetro=Phoenix&slctCity=Scottsdale">
                  Scottsdale, AZ
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=CA&slctMetro=Los+Angeles&slctCity=Sierra+Madre">
                  Sierra Madre, CA
                </a>
              </div>
              <div>
                <a
                  className="hover:text-red-500"
                  href="/find-an-elite-travel-agent?slctState=NY&slctMetro=New+York&slctCity=Woodmere">
                  Woodmere, NY
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AgentSearchFooter;
