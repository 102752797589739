import { canadaStates, states } from './constants';
import Cookies from 'universal-cookie';
import APIService from '../services/services';
import {
  AgentSearchBuildUrlParameters,
  AgentSearchParameters,
  AgentViewModel,
  PhoneAgentViewModel
} from './types';

const cookies = new Cookies();

export const getStateName = (code: string | number) => {
  if (states[code] ? states[code] : canadaStates[code]) {
    return states[code] ? states[code] : canadaStates[code];
  }

  return code;
};

export const agentSearchQueryToFilter = (
  query: AgentSearchBuildUrlParameters,
  currentPage: number
): AgentSearchParameters => {
  return {
    agentCity: query.slctCity || '',
    agentLanguage: query.slctLanguage || '',
    agentMetro: query.slctMetro || '',
    agentName: query.txtAgentName || '',
    agentState: query.slctState || '',
    agentSupplier: query.slctSupplier || '',
    agentZip: query.zip || '',
    agentZipRadius: query.zipRadius || '',
    destination: query.slctDestination ? query.slctDestination.split('|')[0] : '',
    interest: query.slctInterest || '',
    agentId: 0,
    currentPage: currentPage,
    useZipBiasing: false,
    nearby: query.nearby
  };
};

export const agentSearchFilterToParam = (filter: string): string => {
  if (filter === 'destination') {
    return 'slctDestination';
  } else if (filter === 'interest') {
    return 'slctInterest';
  } else if (filter === 'agentState') {
    return 'slctState';
  } else if (filter === 'agentMetro') {
    return 'slctMetro';
  } else if (filter === 'agentCity') {
    return 'slctCity';
  } else if (filter === 'agentName') {
    return 'txtAgentName';
  } else if (filter === 'agentLanguage') {
    return 'slctLanguage';
  } else if (filter === 'agentSupplier') {
    return 'slctSupplier';
  } else if (filter === 'agentZip') {
    return 'zip';
  } else {
    return '';
  }
};
export const getMoreReferralData = () => {
  const cookie = cookies.get('google-utm');
  if (cookie) {
    let param = '';
    Object.entries(cookie).map(([key, value]) => {
      param += `${key}=${value}&`;
    });

    param = param.slice(0, -1);
    if (window.location.search === '') {
      return `?${param}`;
    }
    return `&${param}`;
  }

  return '';
};

export const removeUTMCookies = () => {
  const cookieUTM = cookies.get('google-utm');
  if (cookieUTM) {
    cookies.remove('google-utm', { path: '/' });
  }
};

/**
 * Phone lead
 * @param {*} agent : Agent Profile
 */
export const callMe = (agent: AgentViewModel, callback?: () => void) => {
  const cookieName = `contacted${agent.id.toString()}`;
  // const cookies = new Cookies();
  /**
   * Check if cookie already present
   */
  const cookie = cookies.get(cookieName);
  if (!cookie) {
    const url = window.location.href + getMoreReferralData();
    const data: PhoneAgentViewModel = { agentId: parseInt(agent.id), url: url };
    APIService()
      .gtcService.sendAgentPhoneLead(data)
      .then((response) => {
        if (response.responseStatus === 1) {
          cookies.set(cookieName, true, { expires: new Date(Date.now() + 60 * 60 * 24 * 1000) });
          removeUTMCookies();
        }
        // setLoading(false);
      });
  }
  if (callback) {
    callback();
  }
};

export const objToQueryString = (obj: any) => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return `?${keyValuePairs.join('&')}`;
};

export const removeTags = (str: string) => {
  if (str === null || str === '') return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const blogTypeToPromoType = (blogType: string) => {
  if (blogType.toLowerCase() === 'cruise specials') {
    return 'cruise';
  }
  if (blogType.toLowerCase() === 'exclusive experiences') {
    return 'exclusive experiences';
  }
  if (blogType.toLowerCase() === 'featured destinations') {
    return 'featured destination';
  }
  if (blogType.toLowerCase() === 'hotel specials') {
    return 'hotel';
  }
  if (blogType.toLowerCase() === 'vacation packages') {
    return 'tour';
  }

  return 'ALL';
};
