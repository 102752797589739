import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from '../../helpers/constants';
import SearchBox from '../../components/SearchBox';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import { SwiperButtonNext } from '../../components/SwiperButtonNext';
import { SwiperButtonPrev } from '../../components/SwiperButtonPrev';
import APIService from '../../services/services';
import { Helmet } from 'react-helmet';
import { HeaderText } from '../../components/HeaderText';

function Home() {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [video, setVideo] = useState<string | null>(null);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [bookWithUsData, setBookWithUsData] = useState<any[]>([]);
  const [exploreData, setExploreData] = useState<any[]>([]);

  const getBookWithUs = () => {
    APIService()
      .gtcService.getBookWithUs()
      .then((res) => {
        if (res.responseStatus === 1) {
          setBookWithUsData(res.data);
        }
      });
  };

  const getFeatured = () => {
    APIService()
      .gtcService.getFeatured()
      .then((res) => {
        if (res.responseStatus === 1) {
          setExploreData(res.data);
        }
      });
  };

  useEffect(() => {
    getBookWithUs();
    getFeatured();
    Modal.setAppElement('#root');
    if (!modalIsOpen) setVideo(null);
  }, [modalIsOpen]);

  return (
    <>
      <Helmet>
        <meta name="title" content="International Luxury Travel Agents | Global Travel Collection" />
        <meta
          name="description"
          content="Our 1,500 GTC advisors and agencies are leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment industry."
        />
        <title>International Luxury Travel Agents | Global Travel Collection</title>
      </Helmet>
      <div className="mx-auto relative overflow-y-hidden -mt-24">
        <section className="relative h-[80vh] flex flex-col items-center justify-center text-center text-white pt-5 sm:py-0 px-3">
          <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
            <video
              className="min-w-full min-h-full absolute object-cover"
              src="/videos/header-loop-desktop-v3.mp4"
              autoPlay
              muted
              playsInline
              loop></video>
          </div>
          <div className="video-content text-center">
            <HeaderText title={'WHEREVER YOU’RE GOING, WE’VE BEEN THERE.'} />
            <button
              onClick={() => {
                setVideo('videos/header-modal-desktop.mp4');
                setIsOpen(true);
              }}
              className="text-xl sm:text-2xl py-16 hover:scale-110 flex gap-2 items-center mx-auto text-black  font-ivypresto-display">
              <i className="fa-regular fa-circle-play fa-xl" role="presentation"></i>
              <span>Watch Full Video</span>
            </button>
          </div>
          <section className="w-full absolute bottom-0 text-left text-black">
            <SearchBox />
          </section>
        </section>

        <article className="p-4 mx-auto bg-white 2xl:container">
          <section className="mb-4 mr-0">
            <div className="py-8 mx-2 md:mx-20">
              <h2 className="uppercase">How to work with a Global Travel Collection advisor</h2>
              <p>
                We are the world’s largest collection of international luxury travel agencies. Approximately 1,500 GTC advisors and agencies
                are industry leaders in providing premium travel services to leisure travelers, corporate executives and the entertainment
                industry. The combined global reach and leverage translates into value, recognition, and preferential treatment for its
                world travelers. Wherever you want to go, however you want to get there, whatever you want to do—we have been there.
              </p>
            </div>
          </section>
          <section className="mx-2 md:mx-20 m-10">
            <div className="flex flex-col justify-between gap-10 sm:flex-row">
              <div className="w-full md:w-72">
                <div className="block border-b-2 border-black">
                  <div className="text-left align-middle">
                    <img
                      className="inline h-auto w-7 pb-1.5"
                      alt="small-circle"
                      title="small-circle"
                      width={45}
                      height={45}
                      src="/images/bg/small-circule.svg"
                    />
                    <h2 className="inline text-xl uppercase font-bold">Explore</h2>
                  </div>
                </div>
                <div className="block mt-3">Search for travel agents based on their areas of expertise, or find one in your community.</div>
              </div>

              <div className="w-full md:w-72">
                <div className="block border-b-2 border-black">
                  <div className="text-left align-middle">
                    <img
                      className="inline h-auto w-7 pb-1.5"
                      title="small-circle-2"
                      alt="small-circle-2"
                      width={45}
                      height={45}
                      src="/images/bg/small-circule.svg"
                    />
                    <h2 className="inline text-xl  uppercase font-bold">Connect</h2>
                  </div>
                </div>
                <div className="block mt-3">Contact any of our travel agents by phone, email or text – however you prefer.</div>
              </div>

              <div className="w-full md:w-72">
                <div className="block border-b-2 border-black">
                  <div className="text-left align-middle">
                    <img
                      className="inline h-auto w-7 pb-1.5"
                      alt="small-circle-3"
                      title="small-circle-3"
                      width={45}
                      height={45}
                      src="/images/bg/small-circule.svg"
                    />
                    <h2 className="inline text-xl  uppercase font-bold">Enjoy</h2>
                  </div>
                </div>
                <div className="block mt-3">
                  With a professional travel agent on your side, planning your vacation is fun and stress-free.
                </div>
              </div>
            </div>

            <div className="flex pb-4 mt-10 sm:justify-center">
              <a
                href="/find-an-elite-travel-agent"
                className="w-full py-2 font-normal text-center text-white bg-black border border-black sm:w-72 hover:font-semibold px-7 hover:cursor-pointer">
                Click Here to Connect
              </a>
            </div>
          </section>

          <section className="mb-4 mr-0">
            <div className="py-4 mx-2 md:mx-20">
              <h2 className="uppercase font-bold">Why book with us</h2>
              <p>
                Every time our Advisors plan a trip, they draw from their own real life travel experiences. Read their stories below and get
                inspired.
              </p>
              <div className="md:mt-4">
                <div className="grid gap-3 grid-cols-1 md:grid-cols-2">
                  {bookWithUsData.slice(0, 4).map((data) => (
                    <div className="relative row-span-2" key={data.title}>
                      <a href={data.url}>
                        <div className="book-card"></div>
                        <img src={data.imageUrl} className="w-full" alt={data.title} title={data.title} />

                        <div className="absolute bottom-0 left-0 right-0 px-4 py-2">
                          <p className="mt-1 text-lg text-white">{data.title}</p>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                <div className={`${viewMore ? 'max-h-[500px]' : 'max-h-0'} transition-all delay-150 duration-300 overflow-hidden w-full`}>
                  {' '}
                  <div className="grid gap-3 grid-cols-1 md:grid-cols-2 mt-3">
                    {bookWithUsData.slice(4, 6).map((data) => (
                      <div className="relative row-span-2" key={data.title}>
                        <a href={data.url}>
                          <div className="book-card"></div>
                          <img src={data.imageUrl} className="w-full" alt={data.title} title={data.title} />

                          <div className="absolute bottom-0 left-0 right-0 px-4 py-2">
                            <p className="mt-1 text-lg text-white">{data.title}</p>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                className="w-auto py-2 font-normal text-center text-white bg-black border border-black sm:w-72 
                hover:font-semibold px-7 hover:cursor-pointer"
                role="button"
                aria-label={!viewMore ? 'View More' : 'View Fewer'}
                onClick={() => setViewMore(!viewMore)}>
                {!viewMore ? 'View More' : 'View Fewer'}
              </button>
            </div>
          </section>

          <section className="mb-4 mr-0">
            <div className="py-8 mx-2 md:mx-20">
              <div className="flex flex-col items-center text-center">
                <h2 className="uppercase ">Explore your options</h2>
                <p>We are the world’s largest collection of international luxury travel agencies.</p>
              </div>
              <div className="relative md:mx-auto  flex flex-row">
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  slidesPerGroupSkip={1}
                  modules={[Pagination]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      centeredSlides: false,
                      centeredSlidesBounds: false
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 0,
                      centeredSlides: false,
                      centeredSlidesBounds: false
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 0,
                      slidesPerGroup: 4,
                      centeredSlides: false,
                      centeredSlidesBounds: false
                    }
                  }}
                  onSlideChange={() => console.log('slide change')}>
                  <div className="absolute inset-y-0 left-0 z-10 flex items-center">
                    <SwiperButtonPrev className="" />
                  </div>
                  {exploreData.map((promo) => (
                    <SwiperSlide key={promo.title}>
                      <div className="swiper-slide p-4">
                        <div className="w-full">
                          <a title={promo.title} href={promo.url} className="cursor-pointer">
                            <img className="w-full " src={promo.thumbnailImage} alt={promo.title} title={promo.title} />
                          </a>
                        </div>
                        <div className="text-center bg-black min-h-[120px] py-4 flex justify-center items-center">
                          <p className="m-0 text-sm  md:text-sm text-white uppercase font-normal font-[Avenir-lt-Std] px-2">
                            {promo.title}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}

                  <div className="absolute inset-y-0 right-0 z-10 flex items-center">
                    <SwiperButtonNext className="" />
                  </div>
                </Swiper>
              </div>
            </div>
          </section>
        </article>

        <section className="z-50 relative font-light text-white bg-black  sm:px-4  pb-8 items-center">
          <div className="flex flex-col md:flex-row h-auto pt-10 gap-10 md:gap-20 px-10 mx-auto 2xl:container">
            <div className="self-center">
              <img src="/images/logo-GTC.svg" alt="logo" title="logo" className="w-32 h-auto sm:w-40" width={132} height={94} />
            </div>
            <div>
              <div>
                <div className="flex justify-center mx-2 mb-2 ml-4 space-x-4 sm:justify-start">
                  <div className="py-2 text-white border-b-2 border-white">Who is Global Travel Collection?</div>
                </div>
                <div className="py-8 px-4 text-sm text-center sm:text-left">
                  <div className="md:w-3/4">
                    Global Travel Collection (GTC) represents the largest and most sophisticated community of premier travel agencies
                    worldwide, serving leisure, corporate, incentive, and entertainment travel needs.
                  </div>
                  <div className="flex flex-col gap-6 mt-10 sm:flex-row items-center ">
                    <div>
                      <button
                        onClick={() => {
                          setVideo('videos/header-modal-desktop.mp4');
                          setIsOpen(true);
                        }}
                        className="py-2 font-normal text-white border border-white hover:text-gray-200 px-7 cursor-pointer">
                        View Video
                      </button>
                    </div>
                    <div>
                      <a
                        href="/about-gtc"
                        className="py-2 font-normal text-white border border-white hover:text-gray-200 px-7 cursor-pointer">
                        View Our Family Of Brands
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <img
          src="/images/gtc-home-bg.png"
          className="absolute top-96  w-64 right-0 md:hidden flex"
        />
        <img src="/images/gtc-home-bg.png" className="absolute bottom-28 right-0 md:flex hidden" /> */}
      </div>

      <Modal isOpen={modalIsOpen && video !== null} style={customStyles}>
        <div className="modalVideo">
          <button onClick={() => setIsOpen(false)} className="close-modal text-center cursor-pointer">
            ×
          </button>
          <video
            id="modalVideo"
            autoPlay
            controls
            poster="/images/hero/video-full-poster.jpg"
            onEnded={() => setIsOpen(false)}
            src={video || ''}></video>
        </div>
      </Modal>
    </>
  );
}

export default Home;
