function ThankYouExperienced() {
  return (
    <>
      <section className="w-full">
        <div
          className="large-hero justify-center px-4 bg-cover"
          role="img"
          aria-label="hero img"
          style={{
            backgroundImage: `url('/images/joinus/Thank you page 2 hero image_2110x410 px.webp')`
          }}>
          <div className="flex flex-col items-center">
            <div>
              <h1 className="text-sm sm:text-2xl uppercase font-light text-gtc4 text-center">THANK YOU FOR CONTACTING US</h1>
            </div>
          </div>
        </div>
      </section>
      <article className="p-4 mx-auto bg-white 2xl:container">
        <section>
          <div className="text-lg">
            Thank you for submitting your Global Travel Collection join us form. We will be in touch soon. You can expect to hear back from
            us within 48 hours to get started!
            <br />
            <br />
            {`We'll be in touch with you shortly to help you and your travel agency save time, money
            and be more efficient. That way you can focus on doing what you do best, selling travel.`}
          </div>
        </section>
      </article>
    </>
  );
}

export default ThankYouExperienced;
