import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AgentDetailViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import ClientReview from '../layout/agent/ClientReview';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import ClientTestimonial from '../layout/agent/ClientTestimonial';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import EmailForm from '../layout/EmailForm';
import PhoneForm from '../layout/PhoneForm';
import AgentConnectionView from '../layout/agent/AgentConnection';
import queryString from 'query-string';
import { AgentMapView } from '../layout/agent/AgentMap';
import TravelExperience from '../layout/agent/TravelExperience';
import { getStateName } from '../../helpers/functions';
import AllTravelExperience from '../layout/agent/AllTravelExperience';
import StarRatings from 'react-star-ratings';

function Blog() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<AgentDetailViewModel | null>(null);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  const parsed = queryString.parse(searchParams.toString());

  const getAgent = () => {
    setLoading(true);
    APIService()
      .gtcService.fetchAgentBlog({
        agentId: parseInt(parsed.agentId as string),
        bioId: parsed.bioId as string | null,
        blogId: parsed.blogId as string | null,
        preview: parsed.preview ? (parsed.preview as unknown as boolean) : false
      })
      .then((res) => {
        setLoading(false);
        if (res.responseStatus === 1) {
          setData(res.data);
        }
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(searchParams.toString());
    console.log(parsed);
    if (parseInt(parsed.agentId as string) > 0) getAgent();
  }, [id]);

  const agentCard = useMemo(() => {
    return (
      <div className="flex flex-col text-sm justify-center bg-gtc6 md:w-96 mx-1 md:mx-0">
        <div>
          <img src={data?.agent.photoUrl} className="w-full" alt={`${data?.agent.fullName} Luxury Travel Agent ${data?.agent.city}`} />
        </div>
        <div className="text-center justify-center flex flex-col gap-2">
          <a href={`/travel-agent/${data?.agent.id}`} className="font-bold mt-2">
            Back to my Main Profile
          </a>
          {data && (
            <div className="flex flex-row gap-1 justify-center items-center">
              {data?.agent.rating > 0 && (
                <StarRatings
                  rating={data.agent.rating}
                  starRatedColor="#ff6d41"
                  // changeRating={this.changeRating}
                  numberOfStars={5}
                  starDimension="16"
                  starSpacing="0.5px"
                  name="rating"
                />
              )}
              {data.agent.reviewsCount > 0 && <div className="mt-1 text-xs">({data.agent.reviewsCount} reviews)</div>}
            </div>
          )}
          <a href={`/travel-agent/${data?.agent.id}`}>See All</a>
          <>
            {data?.agent.reviewsCount !== undefined && data?.agent.reviewsCount > 10 && <> {data?.agent.reviewsCount} Reviews </>}
            {data?.agent.reviewsCount !== undefined && data?.agent.reviewsCount > 10 && <> | {data?.agent.recommended}% Recommended</>}
          </>
          {<AgentConnectionView agent={data?.agent} />}
          <div className="grid grid-cols-2 justify-between gap-2 mx-1">
            {data?.agent.hasEmail && (
              <button
                className={[
                  ' w-full h-12 flex justify-center items-center flex-1',
                  data.agent.isOffline ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
                ].join(' ')}
                disabled={data.agent.isOffline}
                title={`Email ${data?.agent.firstName}`}
                onClick={() => {
                  setModalOpen('Email');
                }}
                aria-label={`Email ${data?.agent.firstName}`}
                role="button"
                tabIndex={0}>
                <i role="presentation" className="fa-solid fa-envelope fa-2xl text-white"></i>
              </button>
            )}
            {data?.agent.hasPhone && (
              <button
                className={[
                  ' w-full h-12 flex justify-center items-center flex-1',
                  data.agent.isOffline ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
                ].join(' ')}
                disabled={data.agent.isOffline}
                title={`Call ${data?.agent.firstName}`}
                onClick={() => {
                  setModalOpen('Phone');
                }}
                aria-label={`Call ${data?.agent.firstName}`}
                role="button"
                tabIndex={0}>
                <i role="presentation" className="fa-solid fa-phone fa-2xl text-white"></i>
              </button>
            )}
            {/* {data?.agent.hasVideoAppointment && (
              <button
                className={[
                  ' w-full h-12 flex justify-center items-center flex-1',
                  data.agent.isOffline
                    ? '!cursor-not-allowed bg-gray-400'
                    : 'cursor-pointer bg-black hover:bg-gtc7 '
                ].join(' ')}
                disabled={data.agent.isOffline}
                title={`Meet ${data?.agent.firstName}`}
                onClick={() => {
                  setModalOpen('Meet');
                }}>
                <i role="presentation" className="fa-solid fa-calendar-days fa-2xl text-white"></i>
              </button>
            )} */}
          </div>
        </div>
        <div className="items-start flex flex-col gap-6 my-4 mx-2 min-h-72 text-[16px]">
          {data?.agent.isOffline && (
            <div className="flex-1 flex flex-col pr-5 justify-center items-center text-center">
              <div className="font-bold text-base md:text-lg">{data?.agent.isOfflineMsg}</div>
              <div className="text-base md:text-lg">
                Have an Emergency?
                <br />
                <button
                  onClick={() => {
                    setModalOpen('Email');
                  }}
                  className="text-gtc7 hover:text-black text-sm cursor-pointer">
                  Contact Me Here
                </button>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2 text-[14px]">
            <div className="font-bold uppercase">Agency</div>
            <div>{data?.agent.agencyName}</div>
          </div>
          <div className="flex flex-col gap-2 text-[14px]">
            <div className="font-bold uppercase">Destinations</div>
            <div>{data?.agent.destinations.join(', ')}</div>
          </div>
          <div className="flex flex-col gap-2 text-[14px]">
            <div className="font-bold uppercase">SPECIALTIES</div>
            <div>{data?.agent.interests.join(', ')}</div>
          </div>
          {data?.agent.suppliers && data?.agent.suppliers.length > 0 && (
            <div className="flex flex-col gap-2 text-[14px]">
              <div className="font-bold uppercase">Suppliers</div>
              <div>{data?.agent.suppliers.join(', ')}</div>
            </div>
          )}
          {data?.certifications && data.certifications?.length > 0 && (
            <div className="flex flex-col gap-2 text-[14px]">
              <div className="font-bold uppercase">Certificates & Awards</div>
              <div>{data?.certifications?.map((x) => x.certification).join(', ')}</div>
            </div>
          )}
        </div>
      </div>
    );
  }, [data]);
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={data?.pageTitle || ''} />
        <meta name="description" content={data?.pageDescription} />
        <title>{data?.pageTitle || ''}</title>
      </Helmet>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">Fetching...</h2>
        </div>
      )}
      {data && (
        <div className="mx-auto relative 2xl:container">
          <div className="text-base text-black gap-1 bg-gtc8 p-1.5">
            <a href="/find-an-elite-travel-agent" className="pr-1">
              Home {'>'}
            </a>
            <a href={`/find-an-elite-travel-agent?slctState=${data.agent.state}`} className="pr-1">
              {getStateName(data.agent.state)} {'>'}
            </a>
            <a href={`/travel-agent/${data.agent.id}`} className="pr-1">
              {data.agent.fullName} {'>'}
            </a>
            <a href={`/travel-agent/${data.agent.id}/${data.agent.websiteDisplayTitleSlug}?bioId=${parsed.bioId || 0}`} className="pr-1">
              {data.agent.websiteDisplayTitle} {'>'}
            </a>
            {parsed.blogId ? (
              <a href={`/travel-agent/agent-blog?agentId=${data.agent.id}`}>All Travel Experiences {'>'}</a>
            ) : (
              <div className="text-gtc9 inline">All Travel Experiences</div>
            )}

            <div className="text-gtc9 inline">{data.agent.title}</div>
          </div>
          <div className="bg-white flex flex-col md:flex-row gap-4 my-4">
            <div className="text-6xl text-black">{agentCard}</div>
            <div className="text-6xl text-black md:mr-10 flex flex-col gap-6 px-2 md:px-0 md:mt-6 min-w-0 w-full">
              <div className="flex flex-col gap-2 mt-1 border-gtc3 border-b pb-3">
                <h1 className="uppercase text-5xl font-light m-0">{data.agent.fullName}</h1>
                <div className="text-lg font-bold">{data.agent.city} Travel Agent</div>
              </div>
              {data.agent.title !== '' && (
                <div className="flex flex-col gap-5 border-gtc3 border-b pb-4">
                  <div className="flex flex-col gap-4">
                    <div className="font-bold text-4xl">{data.agent.title}</div>
                    <div className="text-sm" dangerouslySetInnerHTML={{ __html: data.agent.bioText }} />
                  </div>
                </div>
              )}
              {data.showSpecialties && (
                <div className="flex flex-col gap-8 border-gtc3 border-b pb-4">
                  <h2 className="font-bold text-3xl">Travel Specialties</h2>
                  <div className="flex flex-col md:flex-col gap-8 ">
                    {data.specialities?.map((x) => (
                      <div className="flex flex-col md:flex-row  gap-4  text-center " key={x.title}>
                        <a href={x.url}>
                          <div
                            className="hover:opacity-40 ease-in-out duration-500 
                             w-fit">
                            <img src={x.image} alt={x.imageCaption} className="object-cover" style={{ width: '256px' }} />
                          </div>
                        </a>
                        <div className="flex flex-col text-sm flex-1">
                          <a href={x.url} className="text-sm font-semibold text-left">
                            {x.title}
                          </a>
                          <div>
                            <a
                              href={x.url}
                              className="text-sm font-thin text-left"
                              dangerouslySetInnerHTML={{
                                __html: x.shortText.concat(
                                  `<a href="@item.Url" class="text-black font-bold">Read more from my ${x.title} bio.</a>`
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {data.certifications && data.certifications?.length > 0 && (
                <div className="flex flex-col gap-4 border-gtc3 border-b pb-4">
                  <h2 className="font-bold text-3xl">Certificates & Awards</h2>
                  <div className="flex flex-col md:flex-row gap-4 ">
                    {data.certifications?.map((x) => (
                      <div
                        className="inline-flex w-24 h-24 p-2 text-center tracking-normal text-white bg-black text-xs"
                        key={x.certification}>
                        {x.certification}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {data.showExperiences && <TravelExperience data={data} />}
              {!data.showExperiences && parsed.blogId === undefined && <AllTravelExperience data={data} />}
              {data.showTestimonials && data.testimonials && data.testimonials?.length > 0 && (
                <div className="border-gtc3 border-b pb-4">
                  <ClientTestimonial testimonials={data.testimonials || []} />
                </div>
              )}
              {data.showReviews && data.reviews && data.reviews?.length > 0 && (
                <div className="border-gtc3 border-b pb-4">
                  <ClientReview reviews={data.reviews || []} agent={data.agent} onReviewFinish={getAgent} />
                </div>
              )}
              {data.showMap && <AgentMapView data={data} />}
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {data?.agent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...data?.agent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && (
                // eslint-disable-next-line react/jsx-no-undef
                <PhoneForm agent={data?.agent} callback={() => setModalOpen('Email')} />
              )}
              {modalOpen === 'Email' && <EmailForm agent={data?.agent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Blog;
